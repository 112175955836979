<template>
  <div>
    <h4>ข้อมูลผู้ใช้</h4>
    <div class="card">
      <div class="card-header text-light p-2">
        ข้อมูลส่วนตัว
      </div>
      <div class="card-body">

        <div class="row no-gutters mb-3">
          <div class="col-md-3">
            <span>Username</span>
          </div>
          <div class="col-md-9">
            <span class="text-primary">{{username}}</span>
          </div>
        </div>

        <div class="row no-gutters mb-3">
          <div class="col-md-3">
            <span>ชื่อ - นามสกุล</span>
          </div>
          <div class="col-md-9">
            <span class="text-primary">{{fullname}}</span>
          </div>
        </div>

        <div class="row no-gutters mb-3">
          <div class="col-md-3">
            <span>เบอร์โทรศัพท์</span>
          </div>
          <div class="col-md-9">
            <span class="text-primary">{{tel}}</span>
          </div>
        </div>

        <div class="row no-gutters mb-3">
          <div class="col-md-3">
            <span>Line ID</span>
          </div>
          <div class="col-md-9">
            <span class="text-primary">{{lineId}}</span>
          </div>
        </div>

        <div class="row no-gutters mb-3">
          <div class="col-md-3">
            <span>วันที่สมัครสมาชิก</span>
          </div>
          <div class="col-md-9">
            <span class="text-primary">{{registerDate}}</span>
          </div>
        </div>
        <hr>
        <div>
          <button class="btn btn-sm btn-info mr-2" @click="isChangeProfile = !isChangeProfile"><i class="fas fa-pencil-alt"></i> แก้ไขข้อมูลส่วนตัว</button>
          <button class="btn btn-sm btn-warning" @click="isChangePassword = !isChangePassword"><i class="fas fa-key"></i> เปลี่ยนรหัสผ่าน</button>
        </div>

      </div>
    </div>

    <h4>อัตราจ่าย</h4>
    <div class="card border-info position-relative">
      <div class="card-body p-3">
        <div v-for="group in groups" :key="group._id" class="mb-3">
          <h5 class="text-primary mb-0">{{group.groupTitle}}</h5>
          <div class="row no-gutters mx-n2">
            <div v-for="rate in group.payRates" :key="rate.rateId" class="col-xl-4 col-md-6 p-2">
              <table class="table table-info table-sm table-bordered table-hover mb-0">
                <thead class="bg-info">
                  <tr>
                    <th width="30%">{{rate.rateTitle}}</th>
                    <th width="20%">จ่าย<br/><small>(บาท)</small></th>
                    <th width="20%">ลด<br/><small>(%)</small></th>
                    <th width="30%">ขั้นต่ำ<br/><small>(บาท)</small></th>
                  </tr>
                </thead>
                <tbody style="background-color: #FFF">
                  <tr :class="[{'alert-secondary text-secondary': !isAvailable(rate.rates, 'threeNumberTop')}]">
                    <td class="text-left">3 ตัวบน</td>
                    <td class="text-right">{{rate.rates | pay('threeNumberTop') | amountFormat(2, '0.00')}}</td>
                    <td class="text-right">{{rate.rates | discount('threeNumberTop') | amountFormat(2, '0.00')}}</td>
                    <td class="text-center">{{group.betLimit | betLimit('threeNumberTop')}}</td>
                  </tr>
                  <tr :class="[{'alert-secondary text-secondary': !isAvailable(rate.rates, 'threeNumberTode')}]">
                    <td class="text-left">3 ตัวโต๊ด</td>
                    <td class="text-right">{{rate.rates | pay('threeNumberTode') | amountFormat(2, '0.00')}}</td>
                    <td class="text-right">{{rate.rates | discount('threeNumberTode') | amountFormat(2, '0.00')}}</td>
                    <td class="text-center">{{group.betLimit | betLimit('threeNumberTode')}}</td>
                  </tr>
                  <tr :class="[{'alert-secondary text-secondary': !isAvailable(rate.rates, 'twoNumberTop')}]">
                    <td class="text-left">2 ตัวบน</td>
                    <td class="text-right">{{rate.rates | pay('twoNumberTop') | amountFormat(2, '0.00')}}</td>
                    <td class="text-right">{{rate.rates | discount('twoNumberTop') | amountFormat(2, '0.00')}}</td>
                    <td class="text-center">{{group.betLimit | betLimit('twoNumberTop')}}</td>
                  </tr>
                  <tr :class="[{'alert-secondary text-secondary': !isAvailable(rate.rates, 'twoNumberBottom')}]">
                    <td class="text-left">2 ตัวล่าง</td>
                    <td class="text-right">{{rate.rates | pay('twoNumberBottom') | amountFormat(2, '0.00')}}</td>
                    <td class="text-right">{{rate.rates | discount('twoNumberBottom') | amountFormat(2, '0.00')}}</td>
                    <td class="text-center">{{group.betLimit | betLimit('twoNumberBottom')}}</td>
                  </tr>
                  <tr :class="[{'alert-secondary text-secondary': !isAvailable(rate.rates, 'runTop')}]">
                    <td class="text-left">วิ่งบน</td>
                    <td class="text-right">{{rate.rates | pay('runTop') | amountFormat(2, '0.00')}}</td>
                    <td class="text-right">{{rate.rates | discount('runTop') | amountFormat(2, '0.00')}}</td>
                    <td class="text-center">{{group.betLimit | betLimit('runTop')}}</td>
                  </tr>
                  <tr :class="[{'alert-secondary text-secondary': !isAvailable(rate.rates, 'runBottom')}]">
                    <td class="text-left">วิ่งล่าง</td>
                    <td class="text-right">{{rate.rates | pay('runBottom') | amountFormat(2, '0.00')}}</td>
                    <td class="text-right">{{rate.rates | discount('runBottom') | amountFormat(2, '0.00')}}</td>
                    <td class="text-center">{{group.betLimit | betLimit('runBottom')}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EEEEEE"
        :height="60"
        :width="60"
        color="#51CE8D"
        loader="dots"
      />
    </div>

    <ChangePasswordModal :is-show="isChangePassword" @close="isChangePassword = false" />
    <EditProfileModal :is-show="isChangeProfile" @close="isChangeProfile = false" />
  </div>
</template>
<script>
import moment from '@/helpers/moment'

import ChangePasswordModal from '@/views/components/ChangePasswordModal'
import EditProfileModal from '@/views/components/EditProfileModal'
import AuthService from '@/services/authService'
import cAlert from '@/helpers/alert'

export default {
  name: 'Profile',
  components: {
    ChangePasswordModal,
    EditProfileModal
  },
  data () {
    return {
      data: null,
      isLoading: false,
      isChangePassword: false,
      isChangeProfile: false
    }
  },
  computed: {
    username() {
      return this.$store.state.userProfile.username
    },
    fullname() {
      return `${this.$store.state.userProfile.firstName} ${this.$store.state.userProfile.lastName}`
    },
    tel() {
      return this.$store.state.userProfile.tel || '-'
    },
    lineId() {
      return this.$store.state.userProfile.lineId || '-'
    },
    registerDate() {
      if(this.$store.state.userProfile.createdAt) {
        return moment(this.$store.state.userProfile.createdAt).format("YYYY-MM-DD HH:mm:ss")
      }else{
        return '-'
      }
    },
    groups() {
      return this.data || []
    }
  },
  methods: {
    loadPayRates() {
      this.isLoading = true
      AuthService.getRates().then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดอัตราจ่ายไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    isAvailable(rate, type) {
      return rate?.[type]?.isAvailable
    }
  },
  mounted() {
    this.loadPayRates()
  },
  filters: {
    pay(rate, type) {
      return rate?.[type]?.pay
    },
    discount(rate, type) {
      return rate?.[type]?.discount
    },
    betLimit(limit, type) {
      const min = (limit?.[type]?.min||0)//.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      const max = (limit?.[type]?.max||0)//.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return `${min} - ${max}`
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  font-size: 85%;
  thead {
    tr {
      th {
        vertical-align: middle;
        font-weight: normal;
        text-align: center;
        padding: 5px;
        line-height: 1;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        padding: 5px 2px;
        line-height: 1;
      }
    }
  }
}
</style>
